/**Footer**/
.copyright {
  font-size: .875rem;

  .copyright-line {
    width: 100%;

    .icp-br {
      display: none;
    }
  }
}
