.page {
  position: relative;
  width: 100%;
  max-width: 980px;
  margin: 0 auto;
  padding-top: $page-padding-top-desktop;
}

@import "_single";
@import "_special";
@import "_archive";
@import "_home";
@import "_404";
